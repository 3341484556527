// https://htmlacademy.ru/blog/js/vue-3-router

import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from '@/App.vue'



const router = createRouter({
  routes: [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/HelloWorld.vue')
  }],
  history: createWebHistory()
})

const app = createApp(App)
app.use(router)
app.mount('#app')

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
  });
